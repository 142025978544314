.panel {
  @apply transition-all duration-300 ease-in;
  background-image: linear-gradient(
    0deg,
    rgba(18, 18, 18, 0.5),
    rgba(18, 18, 18, 0.4) 51%,
    rgba(18, 18, 18, 0)
  );
  background-position: 0 80%;
  background-size: 200% 200%;
}

.panel:hover {
  background-position: 0 0;
}
